<template>
  <v-container>
    <v-form @submit.prevent="login">
      <v-text-field v-model="username" label="Username" required></v-text-field>
      <v-text-field v-model="password" label="Password" type="password" required></v-text-field>
      <v-btn type="submit">Login</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import AuthService from '@/services/auth';

export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    async login() {
      try {
        const result = await AuthService.login(this.username, this.password);

        if (result.access_token) {
          if (result.role === 'admin') {
            this.$router.push('/admin');
          } else if (result.role === 'user') {
            this.$router.push('/user-home');
          }
        } else {
          alert('Invalid login');
        }
      } catch (error) {
        console.error('Login error:', error);
        //alert('An error occurred during login');
      }
    }
  }
};
</script>