<template>
  <v-app-bar app color="primary" dark>
    <v-toolbar-title>
      <img :src="logo" alt="AI Ready" class="logo" @click="goHome">
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <p v-if="currentUser" class="font-weight-bold"> {{ username }} at {{ client_name }}</p>
    <v-menu class="menu">
            <template v-slot:activator="{ props }">
              <v-btn class="icon_menu" icon="mdi-dots-vertical" variant="text" v-bind="props"></v-btn>
            </template>

            <v-list>
                <v-list-item>
                <router-link to="/profile">
                  <v-list-item-title>Profile</v-list-item-title>
                </router-link>
                </v-list-item>
              
                
              <v-btn v-if="currentUser" @click="logout">Logout</v-btn>

            </v-list>
          </v-menu>
    
    
  </v-app-bar>
</template>

<script>
import AuthService from '@/services/auth';
import logo from '@/assets/aiready.png';

const apiUrl = process.env.VUE_APP_API_URL;

export default {
  name: 'NavbarComponent',
  data() {
    return {
      username: '',
      client_name: '',
      currentUser: AuthService.getCurrentUser(),
      logo
    };
  },
  created() {
    this.fetchUserData();
  },
  methods: {
    logout() {
      console.log("Logout button clicked");
      AuthService.logout()
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_id');
      localStorage.removeItem('role');
      localStorage.removeItem('refresh_token');
      this.username = '';
      this.client_name = '';
      this.$router.push('/login');
    },
    goHome() {
      // this.$router.go('/admin');
      this.$router.push(`/`);
    },
    async fetchUserData() {
      try {
        const response = await fetch(`${apiUrl}/api/me`,{ 
          method: 'POST', 
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'

          },
          body: JSON.stringify({ 'user_id': localStorage.getItem('user_id') })
        });
        const data = await response.json();
        this.username = data.username;
        this.client_name = data.client;
      } catch (error) {
        console.error(error);
      }
    },
    updateUserData() {
      this.fetchUserData();
    }
  },
  watch: {
    '$route'() {
      this.currentUser = AuthService.getCurrentUser();
      document.title = 'Ai Ready';
      // - ' + this.$route.name;
    }
  },
  mounted() {
    this.updateUserData();
    document.title = 'Ai Ready';
  }
};
</script>

<style scoped>
.v-btn {
  margin-left: 10px;
}
.v-app-bar {
  background-color: black !important; /* Cor de fundo preta */
}
.logo {
  height: 40px; /* Ajuste o tamanho da logo conforme necessário */
  margin-left: 10px;
  margin-top: 10px;
}
.icon_menu {
  /* margin-top: 10px; */
  margin-right: 30px;
}
</style>