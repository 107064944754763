<template>
  <v-app>
    <NavbarComponent />
    <v-main class="main">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavbarComponent from './components/NavbarComponent.vue';


export default {
  name: 'App',
  components: {
    NavbarComponent,
  },
};
</script>

<style>

.main {
  padding-top: 15%;
  min-height: calc(100vh - 64px);
  background-color: #f5f5f5;
}

</style>
